<template>
  <textarea
    id="city-textarea"
    ref="cityRef"
    data-test="input-city-change-modal"
    :value="city"
    autocomplete="honorific-suffix"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    class="city-modal__textarea flex-grow"
    maxlength="100"
    :placeholder="placeholder"
    rows="1"
    style="overflow-y: hidden"
    type="text"
    @input="input"
    @keydown="keyDown"
  />
</template>

<script setup lang="ts">
const props = defineProps({
  city: {},
})

const cityRef = ref()

const emit = defineEmits(['input', 'keyDown'])

const input = (e) => {
  emit('input', e)
}
const keyDown = (e) => {
  emit('keyDown', e)
}

const defaultShow = ref(true)
const currentTextIndex = ref(0)
const currentCharacterIndex = ref(0)
let typingInterval
const defaultPlaceholder = ref('Введите город')

const placeholderTexts = [
  'Москва',
  'Санкт-Петербург',
  'Ростов-на-Дону',
  'Екатеринбург',
  'Оренбург',
  // Добавьте остальные строки, если нужно
]
const placeholder = computed(() => {
  if (defaultShow.value) return defaultPlaceholder.value
  // Возвращаем следующий символ строки
  return placeholderTexts[currentTextIndex.value].slice(0, currentCharacterIndex.value + 1)
})

// function typeNextCharacter() {
//   if (currentTextIndex.value < placeholderTexts.length) {
//     if (currentCharacterIndex.value < placeholderTexts[currentTextIndex].length) {
//       placeholder.value
//         += placeholderTexts[currentTextIndex][currentCharacterIndex]
//       currentCharacterIndex.value++
//     }
//     else {
//       placeholder.value = placeholder.value.slice(0, -1)
//       if (placeholder.value === '') {
//         currentCharacterIndex.value = 0
//         currentTextIndex.value++
//       }
//     }
//   }
//   else {
//     if (placeholder.value || placeholder.value === '')
//       placeholder.value = 'Введите город'
//     clearInterval(typingInterval)
//     setTimeout(() => {
//       if (placeholder.value || placeholder.value === '')
//         placeholder.value = 'Введите город'
//     }, 5)
//     setTimeout(() => {
//       currentTextIndex.value = 0
//       placeholder.value = ''
//       startTypingEffect()
//     }, 2000) // Пауза перед началом заново
//   }
// }

// function startTypingEffect() {
//   if (typingInterval) {
//     clearInterval(typingInterval)
//     placeholder.value = ''
//   }
//
//   typingInterval = setInterval(typeNextCharacter, 100)
// }
function startTypingEffect() {
  let reverseType = false
  typingInterval = setInterval(() => {
    // Проверка на конец строки и переключение на следующую строку
    if (currentCharacterIndex.value < placeholderTexts[currentTextIndex.value]?.length && !reverseType) {
      currentCharacterIndex.value++
      if (currentCharacterIndex.value === placeholderTexts[currentTextIndex.value]?.length) reverseType = true
    }
    else if (reverseType && currentCharacterIndex.value > 0) {
      currentCharacterIndex.value--
    }
    else {
      reverseType = false
      currentCharacterIndex.value = 0
      currentTextIndex.value++

      // Если все строки пройдены, начинаем с первой
      if (currentTextIndex.value >= placeholderTexts.length) {
        currentTextIndex.value = 0
      }
    }
  }, 100) // Интервал печати
}

defineExpose({ cityRef })

onMounted(() => {
  setTimeout(() => {
    defaultShow.value = false
    startTypingEffect()
  }, 1000)
})
onUnmounted(() => {
  clearInterval(typingInterval)
})
</script>

<style scoped></style>
